<template>
  <div class="wrapper u-flex-column u-col-center">
    <div class="u-flex" style="width: 100%;">
      <!-- <img class="logo" src="@/assets/images/QA/logo@2x.png" alt=""> -->
    </div>
    <img class="bg" src="@/assets/images/QA/home-bg.png" alt="">
    <div class="bottom u-flex-column u-col-center">
      <div class="btn" @click="Click"></div>
      <div class="num">今日有<span>{{ qaNum }}次</span>答题机会</div>
    </div>
    <van-popup v-model:show="show1">
      <div class="popup-box1 u-flex-column u-col-center">
        <div class="content u-flex-column u-col-center">
          <div class="btn" @click="show1 = false, $router.replace('/QA/subject')"></div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="show2">
      <div class="popup-box2 u-flex-column u-col-center">
        <div class="content u-flex-column u-col-center">
        </div>
        <div class="btn" @click="show2 = false"></div>
      </div>
    </van-popup>
    <!-- 加载中 -->
    <loading v-show="pageLadingShow"></loading>
    <!-- 请求失败 -->
    <noNetwork v-if="err" @cb="cb"></noNetwork>
  </div>
</template>

<script>
import noNetwork from '@/components/no_network/index.vue'
import loading from '@/components/loading/index.vue'
export default {
  components: {
    noNetwork,
    loading
  },
  props: {},
  data() {
    return {
      show1: false,
      show2: false,
      qaNum: 0,
      err:false,
      pageLadingShow:true
    }
  },
  watch: {},
  computed: {},
  methods: {
    // postUserInfo() {
    //   postUserInfo({}).then(res => {
    //     this.qaNum = res.data.data.qaNum
    //   }).finally(() => {
    //     this.$toast.clear()
    //   })
    // },
    Click() {
      if (this.qaNum == 0) {
        this.show2 = true
      } else {
        this.show1 = true
      }
    },
    problemList(){
      let res =  this.$api.getProblemList().then(item=>{
        this.qaNum = res.data.qaNum

      })
      // getProblemList().then(res=>{
      //   console.log(res)
      // })
    },
    UserInitialize() {
      let res = this.$api.getUserInitialize().then(item => {
        this.qaNum = item.data.answerNum
      }).catch(e=>{
          this.err = true
      }).finally(()=>{
        this.pageLadingShow = false
      })

    },
    cb(){
      this.err = false
      this.UserInitialize()
    }
  },
  created() {
    this.UserInitialize()
    // this.postUserInfo()
    // this.problemList()
  },
  mounted() { }
}
</script>
<style  lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(../../../assets/images/QA/qaLoadingBg.png);
  background-size: 100% 100%;
  overflow: hidden;

  .logo {
    width: 316px;
    height: 40px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .bg {
    width: 725px;
    height: 1212px;
  }

  .bottom {
    position: absolute;
    bottom: 0;

    .btn {
      width: 540px;
      height: 122px;
      background-image: url(../../../assets/images/QA/btn1.png);
      background-size: 100% 100%;
    }

    .num {
      font-size: 41px;
      font-family: SourceHanSansSC;
      font-weight: 400;
      color: #4C4B4C;
      margin-top: 30px;
      margin-bottom: 30px;

      span {
        color: rgba(211, 43, 39, 1);
      }
    }
  }
 
  &::v-deep .van-popup {
    width: 100% !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 610px;
    background-color: transparent;
  }
  

  .popup-box1 {
    position: relative;

    .content {
      width: 573px;
      height: 593px;
      background-image: url(../../../assets/images/QA/rule.png);
      background-size: 100% 100%;
      

      .btn {
        width: 350px;
        height: 100px;
        margin-top: 450px;
      }
    }


  }

  .popup-box2 {
    position: relative;

    .content {
      width: 579px;
      height: 593px;
      background-image: url(../../../assets/images/QA/sorry.png);
      background-size: 100% 100%;
    }

    .btn {
      width: 438px;
      height: 116px;
      margin-top: -130px;
      // background-image: url(../../../assets/images/QA/know-btn.png);
      // background-size: 100% 100%;
    }
  }
}
</style>